import React, { useState, Fragment } from "react"

import { Box } from "@chakra-ui/core"

import Masonry from "containers/masonry"
import Layout from "containers/layout"
import SEO from "components/seo"
import Media from "containers/media"
// import Ostsee from "./ostsee.inline.svg"
// import Karte from "./karte.inline.svg"
// import Article from "containers/article"
// import EventTeaser from "containers/eventTeaser"
// import Event from "containers/event"
// import LinkTo from "containers/link-to"
import Lightbox from "containers/lightbox"
import { useTranslation } from "gatsby-plugin-react-i18next"

export const onlyClient = typeof window !== "undefined"

export const GalerieTempl = ({ location, data, en: EN }) => {
  const [viewLightbox, setViewLightbox] = useState(false)
  const [lightboxData, setLightboxData] = useState({})
  const { t } = useTranslation()

  const { galeries } = data.strapi

  return (
    <Layout path={location.pathname} bgHeader="greenLight">
      <SEO
        title={
          (data.strapi?.galeriePage &&
            data.strapi?.galeriePage[EN ? "meta_en" : "meta"]?.title) ??
          t("Mediathek")
        }
        description={
          data.strapi?.galeriePage &&
          data.strapi?.galeriePage[EN ? "meta_en" : "meta"]?.description
        }
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
        w="100%"
        pos="relative"
      >
        {onlyClient && (
          <Fragment>
            <Masonry
              breakpointColumns={{
                2: "(min-width: 48em)",
                3: "(min-width: 75em)",
              }}
              gap={["10em", "9em", "5em"]}
              defaultColumn={1}
            >
              {galeries &&
                galeries.map((item, index) => {
                  return (
                    <Media
                      key={index}
                      title={item[EN ? "title_en" : "title"]}
                      subtitle={item[EN ? "subtitle_en" : "subtitle"]}
                      videoUrl={item.videoUrl}
                      imageGray={item.cover && item.cover.imageFileGray}
                      image={item.cover && item.cover.imageFile}
                      text={item[EN ? "text_en" : "text"]}
                      openDefault={item.openDefault && !item.lightbox}
                      lightbox={true}
                      gallery={item.gallery}
                      setData={setLightboxData}
                      viewLightbox={setViewLightbox}
                      copyright={item.cover && item.cover.caption}
                      alt={item.cover && item.cover.alternativeText}
                    />
                  )
                })}
            </Masonry>
          </Fragment>
        )}
      </Box>
      <Lightbox
        text={lightboxData.text}
        title={lightboxData.title}
        subtitle={lightboxData.subtitle}
        images={lightboxData.gallery}
        videoUrl={lightboxData.videoUrl}
        visibleFunc={setViewLightbox}
        visible={viewLightbox}
        animate={true}
        viewImageInfo={viewLightbox}
      />
    </Layout>
  )
}
