import React from "react"
import { Flex, Box } from "@chakra-ui/core"
import { css } from "@emotion/core"
import { useTranslation } from "gatsby-plugin-react-i18next"

import ImageWithOverlay from "components/imageWithOverlaycolorOrChild"

const Media = ({
  title,
  subtitle,
  text,
  image,
  imageGray,
  lightbox,
  gallery,
  setData,
  viewLightbox,
  alt,
  videoUrl,
  copyright,
}) => {
  const { t } = useTranslation()

  const imageAspectRatio =
    (image &&
      image.childImageSharp &&
      image.childImageSharp.fluid.aspectRatio) ||
    1

  const funcData = () => {
    setData({
      title,
      text,
      gallery,
      subtitle,
      videoUrl,
    })
    viewLightbox(true)
  }

  return (
    <>
      <Flex
        direction="column"
        pr={["8em", "5em", "5em"]}
        onClick={() => funcData()}
        cursor="pointer"
        flex="0 1"
        position="relative"
        css={css`
          .more-info {
            visibility: hidden;
          }
          .image-gray {
            visibility: visible;
          }
          @media (hover) {
            &:hover .more-info {
              visibility: visible;
            }
          }
        `}
      >
        {image && (
          <ImageWithOverlay
            image={
              image && image.childImageSharp && image.childImageSharp.fluid
            }
            icon={videoUrl ? "play" : "gallery"}
            imageGray={
              imageGray &&
              imageGray.childImageSharp &&
              imageGray.childImageSharp.fluid
            }
            aspectRatio={imageAspectRatio}
            onlyOverlayAndChild={true}
            frame={lightbox}
            alt={alt}
          >
            <Box
              className="more-info"
              pos="absolute"
              top="0"
              color="white"
              fontSize={["5-5xl", "4xl", "3xl"]}
              fontFamily="Traulha Italica, sans-serif"
              bg="red"
              h="100%"
              w="100%"
              zIndex="5"
              p={["0.5em", "0.5em", "0.5em"]}
            >
              {t("Weitere Informationen")}
            </Box>
          </ImageWithOverlay>
        )}
        {title && (
          <Box
            color="red"
            pt={["0.6em", "0.6em", "0.7em"]}
            fontSize={["5-5xl", "4xl", "3xl"]}
            lineHeight="1.25em"
          >
            {title}
          </Box>
        )}
        {subtitle && (
          <Box
            color="green"
            fontSize={["5-5xl", "4xl", "3xl"]}
            lineHeight="1.25em"
          >
            {subtitle}
          </Box>
        )}
      </Flex>
    </>
  )
}

export default Media
